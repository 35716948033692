import React from "react"
import "./Layout.css"
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

interface LayoutProps {
    children: React.ReactNode;
    location: any;
}
interface LayoutState {
    isNavOpen: boolean;
}

export default class Layout extends React.Component<LayoutProps, LayoutState> {
    constructor(props: LayoutProps) {
        super(props);
        this.state = { isNavOpen: false };
    }

    toggleCollapse = () => {
        this.setState({isNavOpen: !this.state.isNavOpen});
    };

    render(): React.ReactNode {

        const navVariant = "light";

        return (
            <div>
                <Navbar collapseOnSelect={true} variant={navVariant} expand="md">
                    <Navbar.Toggle onClick={this.toggleCollapse}/>
                    <Navbar.Collapse>
                        <Nav>
                            <Nav.Link onClick={this.toggleCollapse} href="/">Rent out your gear</Nav.Link>
                            <Nav.Link onClick={this.toggleCollapse} href="/about">About</Nav.Link>
                            <Nav.Link onClick={this.toggleCollapse} href="/contactus">Contact</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <div>
                    <main>{this.props.children}</main>
                </div>
            </div>
        )
    }
}

import React from "react"
import PropTypes from "prop-types"
import {Helmet} from "react-helmet"
import {useStaticQuery, graphql} from "gatsby"
import logo from "../images/gearinheadlights.png"

interface SEOProps {
    description: string,
    lang: string,
    meta: [],
    title: string,
}

interface SiteMetadata {
    title: string,
    description: string
    author: string,
    keywords: []
    siteUrl: string
}

interface Site {
    site: {
        siteMetadata: SiteMetadata
    }
}

function SEO (props: SEOProps) {
    const site: Site = useStaticQuery(
        graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    author
                    keywords
                    siteUrl
                }
            }
        }
    `
    )
    const metaDescription = props.description || site.site.siteMetadata.description
    const ogImage = site.site.siteMetadata.siteUrl + logo
    let lang = props.lang

    return (
            <Helmet
                htmlAttributes={{lang}}
                title={props.title}
                titleTemplate={`%s | ${site.site.siteMetadata.title}`}
                script={[
                    {"src": "https://kit.fontawesome.com/d14e791fff.js", "type": "text/javascript"}
                ]}
                meta={[
                    {
                        name: `description`,
                        content: metaDescription
                    },
                    {
                        name: "keywords",
                        content: site.site.siteMetadata.keywords.join(",")
                    },
                    {
                        property: `og:title`,
                        content: props.title
                    },
                    {
                        property: `og:description`,
                        content: metaDescription
                    },
                    {
                        property: `og:type`,
                        content: `website`
                    },
                    {
                        name: `twitter:card`,
                        content: `summary`
                    },
                    {
                        name: `twitter:creator`,
                        content: site.site.siteMetadata.author
                    },
                    {
                        name: `twitter:title`,
                        content: props.title
                    },
                    {
                        name: `twitter:description`,
                        content: metaDescription
                    },
                    {
                        property: `og:image`,
                        content: ogImage
                    },
                    {
                        property: `twitter:image`,
                        content: ogImage
                    },
                    {
                        property: `image`,
                        content: ogImage
                    }
                ]
                    .concat(props.meta)
                }
            />
        )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    image: PropTypes.any
}

export default SEO
